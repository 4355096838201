import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'

const ContactPage = () => (
  <Layout
    hero={[
      'Contact us',
      'Podlove is an open initiative and we are happy about your requests and questions. He are the points of contact and information resources we’d like you to use to make things easy for us and you.'
    ]}
  >
    <Seo title="Contact" />
    <section
      className="
        flex
        flex-wrap
        items-stretch
        justify-center
        mb-16
        px-4
        md:px-0
      "
    >
      <div
        className="
          markdown
          ml-auto
          mr-auto
          mt-16
          max-w-2xl
        "
      >
        <h1>Podlove Community: General support with Podlove software and specs</h1>
        <p>Many requests are just general questions or you are looking for some support to get our technologies and software to run the way you want.</p>
        <p>For these kind of requests, your first stop should be the <a
          href="https://community.podlove.org/"
          target="_blank"
          rel="noreferrer"
        >Podlove Community</a> which is an open web forum. Get an account and select the proper category. All the team Podlove members and a larger community around the project is following the discussions and is willing to help. Primary language is english but we process requests and German too (keep in mind english requests will better serve the international community and will widen the number of people that can help).</p>
        <p>As a Germany-based project we also have our eyes at the exceptionally busy and supportive For these kind of requests, your first stop should be the <a
          href="https://sendegate.de/c/podlove/21"
          target="_blank"
          rel="noreferrer"
        >Sendegate</a> community site which also includes a dedicated <a
          href="https://sendegate.de/c/podlove/21"
          target="_blank"
          rel="noreferrer"
        >Podlove category</a> where you can discuss all things Podlove in German.</p>
        <h1>Professional support and private requests</h1>
        <p>If you have any other private request or want to get into some kind of collaboration with us either contact us via <a
          href="https://twitter.com/podlove_org"
          target="_blank"
          rel="noreferrer"
        >Twitter DMs</a> or send us an e-mail at <a href="mailto:podlove@podlove.org">podlove@podlove.org</a>.</p>
      </div>
    </section>
  </Layout>
)

export default ContactPage